export interface IProject {
    title: string;
    company: string;
    credit: string;
    description: string;
    images: CarouselContent[];
    link?: string;
}

export enum ContentType {
    image,
    video,  //Video assumes that it's from youtube.
}

export interface CarouselContent {
    type: ContentType;
    url: string;
    thumb?: string;
}

export const GameProjects: IProject[] =
    [
        {
            title: "Dance Collider",
            company: "Emergewolrds (2018)",
            credit: "Gameplay/Systems Programming, Shader Development",
            description: "It's your time to become a dance master and take down Uriel! Battle 9 of the best dancers from around the globe and compete on global leaderboards. Featuring 45 Songs by F-777 and a VR dance editor. Dance Collider is the ultimate dance and rhythm game.",
            images: [
                {
                    type: ContentType.video,
                    url: "BVRFBXRZ14o",
                },
                {
                    type: ContentType.video,
                    url: "9xWQaHWk25Q",
                },
                {
                    type: ContentType.image,
                    url: "Dance Collider/DC0.jpg",
                    thumb: "Thumbs/DC0.jpg",
                },
                {
                    type: ContentType.image,
                    url: "Dance Collider/DC1.jpg",
                    thumb: "Thumbs/DC1.jpg",
                },
                {
                    type: ContentType.image,
                    url: "Dance Collider/DC2.jpg",
                    thumb: "Thumbs/DC2.jpg",
                },
                {
                    type: ContentType.image,
                    url: "Dance Collider/DC3.jpg",
                    thumb: "Thumbs/DC3.jpg",
                },
                {
                    type: ContentType.image,
                    url: "Dance Collider/DC4.jpg",
                    thumb: "Thumbs/DC4.jpg",
                },
            ],
            link: "https://store.steampowered.com/app/692690/Dance_Collider/",
        },
        {
            title: "RoyalABC: World/Classroom",
            company: "Prosper Education (2022)",
            credit: "Programming",
            description: "Two major parts of an English early learning ecosystem. Classroom allows teachers from all around the world to effectively teach entire courses in English, regardless of their own English proficiency. World then allows their students to continue engaging with their learning content at home in a fun, vibrant, and interactive environment.",
            images: [
                {
                    type: ContentType.video,
                    url: "ZhV2C02nV80",
                },
                {
                    type: ContentType.video,
                    url: "NuFbSzD9OHs",
                },
                {
                    type: ContentType.image,
                    url: "RoyalABC/WORLD0.jpg",
                    thumb: "Thumbs/WORLD0.jpg",
                },
                {
                    type: ContentType.image,
                    url: "RoyalABC/WORLD1.jpg",
                    thumb: "Thumbs/WORLD1.jpg",
                },
                {
                    type: ContentType.image,
                    url: "RoyalABC/WORLD2.jpg",
                    thumb: "Thumbs/WORLD2.jpg",
                },
                {
                    type: ContentType.image,
                    url: "RoyalABC/CLASSROOM0.jpg",
                    thumb: "Thumbs/CLASSROOM0.jpg",
                },
                {
                    type: ContentType.image,
                    url: "RoyalABC/CLASSROOM1.jpg",
                    thumb: "Thumbs/CLASSROOM1.jpg",
                },
            ],
            link: "https://royalabc.com/gb",
        },
        {
            title: "Kindergo 2.0",
            company: "Like A Photon Creative (2020)",
            credit: "Programming, Purging, Purification",
            description: "Kindergo 2.0 is a near complete technical reconstruction of Like A Photon Creative's legacy project: Kindergo. Kindergo is an interactive book library that offers classroom quality, interactive picture books and kids learning games in an immersive education world. Kindergo 2.0 is that, but with far more robust and flexible systems for improving and expanding the game.",
            images: [
                {
                    type: ContentType.image,
                    url: "Kindergo/KIN0.png",
                    thumb: "Thumbs/KIN0.jpg",
                },
                {
                    type: ContentType.image,
                    url: "Kindergo/KIN1.png",
                    thumb: "Thumbs/KIN1.jpg",
                },
                {
                    type: ContentType.image,
                    url: "Kindergo/KIN2.png",
                    thumb: "Thumbs/KIN2.jpg",
                },
                {
                    type: ContentType.image,
                    url: "Kindergo/KIN3.png",
                    thumb: "Thumbs/KIN3.jpg",
                },
            ],
            link: "https://play.google.com/store/apps/details?id=com.likeaphoton.kindergo",
        },
        {
            title: "Rusty's Arcade Rush!",
            company: "Prosper Education (2021 Prototype)",
            credit: "Game Design, Programming",
            description: "A prototype I smashed together in ~2.5 days to pitch a new WarioWare inspired minigame for RoyalABC World. The intent was to have older students engage with the curriculum by interpreting the meaining of words in specific contexts, not just by assosciating a single word with an image. While a whole suite of additional microgrames were designed, unfortunately production was never completed and the game is not available to the public.",
            images: [
                {
                    type: ContentType.image,
                    url: "Rush/RUSH0.png",
                    thumb: "Thumbs/RUSH0.jpg",
                },
                {
                    type: ContentType.image,
                    url: "Rush/RUSH1.png",
                    thumb: "Thumbs/RUSH1.jpg",
                },
                {
                    type: ContentType.image,
                    url: "Rush/RUSH2.png",
                    thumb: "Thumbs/RUSH2.jpg",
                },
                {
                    type: ContentType.image,
                    url: "Rush/RUSH3.png",
                    thumb: "Thumbs/RUSH3.jpg",
                },
            ],
        },
        {
            title: "Nothing of Interest",
            company: "Void Based Accounting (20??)",
            credit: "Gameplay Programming, Network Programming, Game Design",
            description: "A business espionage game full of hidden identities and suspicious briefcases. Deceive your opponents and acquire your target with up to 8 players online; just hope that's not gun in their pocket...<br/> <br/>(Currently still in development.)",
            images: [
                {
                    type: ContentType.image,
                    url: "NoI/NOI0.png",
                    thumb: "Thumbs/NOI0.jpg",
                },
                {
                    type: ContentType.image,
                    url: "NoI/NOI1.png",
                    thumb: "Thumbs/NOI1.jpg",
                },
                {
                    type: ContentType.image,
                    url: "NoI/NOI3.png",
                    thumb: "Thumbs/NOI3.jpg",
                },
                {
                    type: ContentType.image,
                    url: "NoI/NOI6.png",
                    thumb: "Thumbs/NOI6.jpg",
                },
                {
                    type: ContentType.image,
                    url: "NoI/NOI4.png",
                    thumb: "Thumbs/NOI4.jpg",
                },
                {
                    type: ContentType.image,
                    url: "NoI/NOI5.png",
                    thumb: "Thumbs/NOI5.jpg",
                },
            ],
        },
        {
            title: "Dyadic",
            company: "Handsome Dragon Games (2016/2023)",
            credit: "Game Design, Project Management",
            description: "Two players find themselves trapped in ancient ruins after discovering a rare and priceless artifact. Both of you want to be the one to escape ruins with the artifact, but you don’t necessarily have enough equipment to do it on your own. Will both of you work together to try and solve the perilous puzzles that stand in your path? Or will you forsake each other to try and keep the treasure for yourselves? There’s only one way to find out… <br/><br/>Now also available on Nintendo Switch!",
            images: [
                {
                    type: ContentType.video,
                    url: "XfEJvyW1QE0",
                },
                {
                    type: ContentType.video,
                    url: "-GQfV3TFz4U",
                },
                {
                    type: ContentType.image,
                    url: "Dyadic/DY0.jpg",
                    thumb: "Thumbs/DY0.jpg",
                },
                {
                    type: ContentType.image,
                    url: "Dyadic/DY1.jpg",
                    thumb: "Thumbs/DY1.jpg",
                },
                {
                    type: ContentType.image,
                    url: "Dyadic/DY2.jpg",
                    thumb: "Thumbs/DY2.jpg",
                },
                {
                    type: ContentType.image,
                    url: "Dyadic/DY3.jpg",
                    thumb: "Thumbs/DY3.jpg",
                },
                {
                    type: ContentType.image,
                    url: "Dyadic/DY4.jpg",
                    thumb: "Thumbs/DY4.jpg",
                },
                {
                    type: ContentType.image,
                    url: "Dyadic/DY5.jpg",
                    thumb: "Thumbs/DY5.jpg",
                },
            ],
            link: "https://store.steampowered.com/app/442140/Dyadic/",
        },
        {
            title: "Devotional",
            company: "Personal (20??)",
            credit: "Programming, Game Design",
            description: "An incremental game about managing a demonic cult. Convert unsuspecting victims into devout cultists, then sacrifice them and offer their blood (and other assorted viscera) to summon an infernal demon! Just make sure you pick the right one to optimise your cult's economy...<br/> <br/>(Currently still in development.)",
            images: [
                {
                    type: ContentType.image,
                    url: "Devotional/DEV0.png",
                    thumb: "Thumbs/DEV0.jpg",
                },
                {
                    type: ContentType.image,
                    url: "Devotional/DEV1.png",
                    thumb: "Thumbs/DEV1.jpg",
                },
                {
                    type: ContentType.image,
                    url: "Devotional/DEV2.png",
                    thumb: "Thumbs/DEV2.jpg",
                },
                {
                    type: ContentType.image,
                    url: "Devotional/DEV3.png",
                    thumb: "Thumbs/DEV3.jpg",
                },
                {
                    type: ContentType.image,
                    url: "Devotional/DEV4.png",
                    thumb: "Thumbs/DEV4.jpg",
                },
                {
                    type: ContentType.image,
                    url: "Devotional/DEV5.png",
                    thumb: "Thumbs/DEV5.jpg",
                },
                {
                    type: ContentType.image,
                    url: "Devotional/DEV6.png",
                    thumb: "Thumbs/DEV6.jpg",
                },
            ],
        },
        {
            title: "Sanctuary World",
            company: "Like A Photon Creative (2019)",
            credit: "Programming, Game Design",
            description: "A mobile match-3/city building game, created as a tie-in for the animated film series Tales From Sanctuary City. Collect resources by clearing quests for your citizens, then use those resources to build bigger and better houses for new adorable citizens to move into!",
            images: [
                {
                    type: ContentType.image,
                    url: "Sanctuary World/SW0.png",
                    thumb: "Thumbs/SW0.jpg",
                },
                {
                    type: ContentType.image,
                    url: "Sanctuary World/SW1.png",
                    thumb: "Thumbs/SW1.jpg",
                },
                {
                    type: ContentType.image,
                    url: "Sanctuary World/SW2.png",
                    thumb: "Thumbs/SW2.jpg",
                },
            ],
            link: "https://play.google.com/store/apps/details?id=com.likeaphoton.tfscmatch",
        },
        {
            title: "Dark Heresy Character Manager",
            company: "Personal (20??)",
            credit: "Programming, UI Design",
            description: "I run a couple of tabletop RPG campaigns for the game Warhammer 40,000: Dark Heresy. Unfortunately, this game doesn't have any sort of companion app for character sheets. So I made one. It's still a bit rough around the edges, but I've got big plans for version 2.0 actively implementing all of the games content and rules dynamically, instead of just acting mostly as a digital sheet of paper.<br/><br/>(Currently still in development.)",
            images: [
                {
                    type: ContentType.image,
                    url: "DHCM/DHCM0.png",
                    thumb: "Thumbs/DHCM0.png",
                },
                {
                    type: ContentType.image,
                    url: "DHCM/DHCM1.png",
                    thumb: "Thumbs/DHCM1.png",
                },
                {
                    type: ContentType.image,
                    url: "DHCM/DHCM2.png",
                    thumb: "Thumbs/DHCM2.png",
                },
                {
                    type: ContentType.image,
                    url: "DHCM/DHCM3.png",
                    thumb: "Thumbs/DHCM3.png",
                },
                {
                    type: ContentType.image,
                    url: "DHCM/DHCM4.png",
                    thumb: "Thumbs/DHCM4.png",
                },
                {
                    type: ContentType.image,
                    url: "DHCM/DHCM5.png",
                    thumb: "Thumbs/DHCM5.png",
                },
            ],
        },
        {
            title: "Wrong Turn At Fleebux 9",
            company: "Emergeworlds (2018)",
            credit: "Programming",
            description: "Wrong Turn At Fleebux 9 is a VR showcase/experiment in creating different and interesting ways for a player to interact with the world using their hands and a Leap Motion.",
            images: [
                {
                    type: ContentType.video,
                    url: "JHZ_IVUnfPM",
                },
                {
                    type: ContentType.image,
                    url: "Fleebux/FL0.png",
                    thumb: "Thumbs/FL0.jpg",
                },
                {
                    type: ContentType.image,
                    url: "Fleebux/FL1.png",
                    thumb: "Thumbs/FL1.jpg",
                },
                {
                    type: ContentType.image,
                    url: "Fleebux/FL2.png",
                    thumb: "Thumbs/FL2.jpg",
                },
                {
                    type: ContentType.image,
                    url: "Fleebux/FL3.png",
                    thumb: "Thumbs/FL3.jpg",
                },
                {
                    type: ContentType.image,
                    url: "Fleebux/FL4.png",
                    thumb: "Thumbs/FL4.jpg",
                },
            ],
        },
        {
            title: "Arms Race",
            company: "Handsome Dragon Games (2017)",
            credit: "Game Design, Project Management",
            description: "Arms Race puts two players in the shoes of rival diplomats – one from Russia, the other: America – and pits them against each other in a diplomatic battle of focus and determination. When only one of them can walk away victorious from this frantic political free-for-all, who will reign supreme?",
            images: [
                {
                    type: ContentType.image,
                    url: "Arms Race/AR0.png",
                    thumb: "Thumbs/AR0.jpg",
                },
                {
                    type: ContentType.image,
                    url: "Arms Race/AR1.png",
                    thumb: "Thumbs/AR1.jpg",
                },
                {
                    type: ContentType.image,
                    url: "Arms Race/AR2.png",
                    thumb: "Thumbs/AR2.jpg",
                },
                {
                    type: ContentType.image,
                    url: "Arms Race/AR3.png",
                    thumb: "Thumbs/AR3.jpg",
                },
            ],
            link: "https://handsomedragongames.itch.io/arms-race",
        },
        {
            title: "Emakimono",
            company: "Handsome Dragon Games (2015)",
            credit: "Game Design, Project Management",
            description: "Emakimono is an infinite runner (or flier, more so) that has you take control of a small, paper swallow, desperately fleeing from the collapse of its world – a collapse triggered by its own desire of a simple ribbon. Gracefully guide the swallow to glide above and swoop beneath the countless obstacles that stand in its way, and soar as far as you can!",
            images: [
                {
                    type: ContentType.video,
                    url: "NsQuWL9OWvw",
                },
                {
                    type: ContentType.image,
                    url: "Emakimono/EM0.png",
                    thumb: "Thumbs/EM0.jpg",
                },
                {
                    type: ContentType.image,
                    url: "Emakimono/EM1.png",
                    thumb: "Thumbs/EM1.jpg",
                },
                {
                    type: ContentType.image,
                    url: "Emakimono/EM2.png",
                    thumb: "Thumbs/EM2.jpg",
                },
                {
                    type: ContentType.image,
                    url: "Emakimono/EM3.png",
                    thumb: "Thumbs/EM3.jpg",
                },
            ],
            link: "https://handsomedragongames.itch.io/emakimono",
        },
        {
            title: "G.I.A.N.T.S",
            company: "Tiny Sail Games (2017)",
            credit: "Programming",
            description: "Four explorers find themselves dragged across the universe to a mysterious and unknown planet. Their only guide (and the reason they’ve ended up here) is a cute and friendly robot named E.H.U. The four players must work together to navigate this strange new world, and its many puzzles and mysteries. Maybe then they’ll be able to find their way back home once more.<br/> <br/>Winner of SAE Qantm's Creative Achievement Award 2017",
            images: [
                {
                    type: ContentType.image,
                    url: "GIANTS/GNT1.png",
                    thumb: "Thumbs/GNT1.jpg",
                },
                {
                    type: ContentType.image,
                    url: "GIANTS/GNT3.png",
                    thumb: "Thumbs/GNT3.jpg",
                },
                {
                    type: ContentType.image,
                    url: "GIANTS/GNT0.png",
                    thumb: "Thumbs/GNT0.jpg",
                },
            ],
            link: "https://nic-lyness.itch.io/giants",
        },
        {
            title: "Other Stuff",
            company: "Mostly personal or group projects",
            credit: "Mostly programming and game design",
            description: "Over the years I've worked on a lot of small projects for university or game jams or just because I want to. They're not all good, but they've all been valuable in their own way. You can see most of them in more details on my old portfolio/blog.",
            images: [
                {
                    type: ContentType.image,
                    url: "Other Projects/OP10.gif",
                },
                {
                    type: ContentType.image,
                    url: "Other Projects/OP9.gif",
                },
                {
                    type: ContentType.image,
                    url: "Other Projects/OP8.png",
                    thumb: "Thumbs/OP8.jpg",
                },
                {
                    type: ContentType.image,
                    url: "Other Projects/OP7.png",
                    thumb: "Thumbs/OP7.jpg",
                },
                {
                    type: ContentType.image,
                    url: "Other Projects/OP6.png",
                    thumb: "Thumbs/OP6.jpg",
                },
                {
                    type: ContentType.image,
                    url: "Other Projects/OP0.png",
                    thumb: "Thumbs/OP0.jpg",
                },
                {
                    type: ContentType.image,
                    url: "Other Projects/OP1.png",
                    thumb: "Thumbs/OP1.jpg",
                },
                {
                    type: ContentType.image,
                    url: "Other Projects/OP2.png",
                    thumb: "Thumbs/OP2.jpg",
                },
                {
                    type: ContentType.image,
                    url: "Other Projects/OP3.png",
                    thumb: "Thumbs/OP3.jpg",
                },
                {
                    type: ContentType.image,
                    url: "Other Projects/OP4.png",
                    thumb: "Thumbs/OP4.jpg",
                },
                {
                    type: ContentType.image,
                    url: "Other Projects/OP5.png",
                    thumb: "Thumbs/OP5.jpg",
                },
                {
                    type: ContentType.image,
                    url: "Other Projects/OP11.png",
                    thumb: "Thumbs/OP11.jpg",
                },
            ],
            link: "https://jackpilz.wordpress.com/reaching-new-heights/"
        },
    ]

export const WebProjects : IProject[] =
    [
        {
            title: "Tales From Sanctuary City Hub",
            company: "Like A Photon Creative (2019)",
            credit: "Programming",
            description: "The Hub operates a content agregator for the animated film series Tales From Sanctuary City. In its original form, the hub pulled from a database of premade, curated, content. However, it has since been updated to instead scrape specific Facebook and Youtube feeds to populate its content automatically (then dump a static form of the old database content at the end).",
            images: [
                {
                    type: ContentType.image,
                    url: "TFSC Hub/HUB0.png",
                    thumb: "Thumbs/HUB0.jpg",
                },
                {
                    type: ContentType.image,
                    url: "TFSC Hub/HUB1.png",
                    thumb: "Thumbs/HUB1.jpg",
                },
                {
                    type: ContentType.image,
                    url: "TFSC Hub/HUB2.png",
                    thumb: "Thumbs/HUB2.jpg",
                },
                {
                    type: ContentType.image,
                    url: "TFSC Hub/HUB3.png",
                    thumb: "Thumbs/HUB3.jpg",
                },
            ],
            link: "https://www.visitsanctuarycity.com/"
        },
        {
            title: "How To Hero",
            company: "Like A Photon Creative (2020)",
            credit: "Programming",
            description: "An online activity book for young children about being a super hero, created as a tie-in for the animated film Tales From Sancutary City: Combat Wombat. Features a name generator, super power quiz, costume picker, puzzle minigame and more!",
            images: [
                {
                    type: ContentType.image,
                    url: "How To Hero/HTH0.png",
                    thumb: "Thumbs/HTH0.jpg",
                },
                {
                    type: ContentType.image,
                    url: "How To Hero/HTH1.png",
                    thumb: "Thumbs/HTH1.jpg",
                },
                {
                    type: ContentType.image,
                    url: "How To Hero/HTH2.png",
                    thumb: "Thumbs/HTH2.jpg",
                },
                {
                    type: ContentType.image,
                    url: "How To Hero/HTH3.png",
                    thumb: "Thumbs/HTH3.jpg",
                },
                {
                    type: ContentType.image,
                    url: "How To Hero/HTH4.png",
                    thumb: "Thumbs/HTH4.jpg",
                },
            ],
            link: "https://howtohero.visitsanctuarycity.com/",
        },
        {
            title: "Dawn Of The Tenth Age",
            company: "Personal (2023)",
            credit: "Design, Programming, Goofs",
            description: "A little website I threw together in a day as a birthday invitation. Mostly a pile of hacked together code, goofs, highly questionable css, and \"borrowed\" assets, but the flaws are part of the charm. Best viewed with sound and on a large desktop monitor. Is absolutely terrible for mobile, don't look at it on there. You can access the site with the password \"Egg\".",
            images: [
                {
                    type: ContentType.image,
                    url: "Dawn Of The Tenth Age/DAWN0.png",
                    thumb: "Thumbs/DAWN0.jpg",
                },
                {
                    type: ContentType.image,
                    url: "Dawn Of The Tenth Age/DAWN1.png",
                    thumb: "Thumbs/DAWN1.jpg",
                }
            ],
            link: "https://dawn-of-the-tenth-age.netlify.app/",
        },
        {
            title: "IWGHQI Rebirth",
            company: "Personal (2024)",
            credit: "Design, Programming, Goofs",
            description: "Another little website I threw together in a day as a birthday invitation. Again, it's mostly a pile of hacked together code, goofs, highly questionable css, and \"borrowed\" assets, but the flaws are part of the charm. Best viewed with sound and on a large desktop monitor. Is absolutely terrible for mobile, don't look at it on there. Also at the end it CONTAINS A LOT OF FLASHING LIGHTS - so be careful please.",
            images: [
                {
                    type: ContentType.image,
                    url: "IWGHQI/IWGHQI0.png",
                    thumb: "Thumbs/IWGHQI0.jpg",
                },
                {
                    type: ContentType.image,
                    url: "IWGHQI/IWGHQI1.png",
                    thumb: "Thumbs/IWGHQI1.jpg",
                }
            ],
            link: "https://iwghqi.netlify.app/",
        },
        {
            title: "This Website",
            company: "Personal (2021)",
            credit: "Programming",
            description: "You know that website you're on right now? I made that too. Well, most of it anyway. Kate Esser made it real pretty. Check out more of her stuff below.",
            images: [
                {
                    type: ContentType.image,
                    url: "Website/WEB0.png",
                    thumb: "Thumbs/WEB0.jpg",
                },
                {
                    type: ContentType.image,
                    url: "Website/WEB1.png",
                    thumb: "Thumbs/WEB1.jpg",
                },
            ],
            link: "https://www.linkedin.com/in/kate-esser-848852117/",
        },
    ]
