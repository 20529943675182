import React, { PropsWithChildren, useState } from "react";
import { Title } from "./Text Components/Title";
import { MainBackground } from "./MainBackground";
import { PetalMaker } from "./Petals/PetalMaker";
import { ProjectCarousel } from "./Carousels/Project Carousel/ProjectCarousel";
import { GameProjects, WebProjects } from "../data/projects";
import { Footer } from "./Text Components/Footer";
import { Blurb } from "./Text Components/Blurb";
import { ExpandedImage } from "./Carousels/Image Carousel/ExpandedImage";

interface IProps {
    path: string;
}

export const Main = (props: PropsWithChildren<IProps>) => {
    const { children } = props;
    const [popupUrl, setPopupUrl] = useState<string>("");

    const OpenPopup = (url: string) => {
        setPopupUrl(url);
    }

    const ClosePopup = () => {
        setPopupUrl("");
    }
    
    return (
        <>
            <MainBackground />
            <PetalMaker />
            <ExpandedImage url={popupUrl} onClose={ClosePopup}/>
            <div className="hero">
                <Title />
                <div className={"scroll-prompt"}>
                    <p>Scroll Down</p>
                    <p>↓</p>
                </div>
            </div>
            <div className="blurb-container">
                <Blurb />
            </div>
            <div className={"carousel-container"}>
                <ProjectCarousel projects={GameProjects} projectTag={"games"} openPopup={OpenPopup}/>
                <ProjectCarousel projects={WebProjects} projectTag={"web"} openPopup={OpenPopup}/>
            </div>
            {children}
            <Footer />
        </>
    );
};
